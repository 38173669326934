/* Add this CSS to your stylesheet */
.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh; /* Adjust the height as needed to fill the viewport */
  }
  
  .menu {
    /* Style your menu or header here */
  }
  
  .pdf-container {
    /* Style your PDF container here */
}
  