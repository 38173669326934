/* Aggiungi queste regole CSS al tuo file CSS globale o a un file separato */
.navbar-nav .nav-link {
    font-size: 20px; /* Aumenta la dimensione del testo */
  }
  
  .navbar-nav .dropdown-menu {
    font-size: 18px; /* Aumenta la dimensione del testo del menu a comparsa */
  }
  

  